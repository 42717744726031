import Footer from "../footer";
import Header from "../Header";

function AboutUs() {
  return (
    <>
      <div className="LandingBody">
        <Header />
        <div className="container tailwnd-container pb-4"
        style={{ backgroundColor: "#FDF0C8" }}
        >
          <div style={{ backgroundColor: "white" }} className="pb-2 px-2">
          <section className="pt-3 ps-3">
            <h1 className="tailwnd-h2">
              Welcome to Tailwnd – Revolutionizing Education Through Innovation
            </h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              At Tailwnd, we believe that every student deserves a personalized
              learning experience that empowers them to excel. We are a
              cutting-edge EdTech company dedicated to transforming the
              educational landscape by harnessing the power of AI and cognitive
              science to enhance learning outcomes and teacher effectiveness.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Our Vision</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              We envision a world where education is more than just a system of
              rote learning. Our goal is to create an environment where every
              student can thrive, every teacher is empowered, and every
              classroom is a place of discovery and engagement.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Our Mission</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              Tailwnd’s mission is to bridge the gap between traditional
              education and the future of learning. By providing personalized
              learning paths, real-time insights, and scientifically designed
              lesson plans, we aim to make education more accessible, engaging,
              and effective for all.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1  className="tailwnd-h1">What We Do</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              Tailwnd offers a precision learning platform that caters to the
              unique needs of both students and educators. Our AI-powered tools
              provide:
            </p>
            <ul className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">
              <li>
                <p>
                  <strong>Personalized Learning Paths:</strong>Tailored to each
                  student’s cognitive abilities, enhancing comprehension and
                  retention.
                </p>
              </li>
              <li>
                <p>
                  <strong>Real-Time Feedback:</strong>Continuous assessment of
                  student performance, allowing for timely interventions and
                  support.
                </p>
              </li>
              <li>
                <p>
                  <strong>Scientifically Designed Lesson Plans:</strong>Helping
                  teachers deliver content more effectively, reducing their
                  workload while increasing curriculum efficacy.
                </p>
              </li>
              <li>
                <p>
                  <strong>Engaging Learning Experiences:</strong>Gamified
                  learning activities that make education fun and interactive.
                </p>
              </li>
            </ul>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Our Impact</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              We are not just another EdTech company; we are a catalyst for
              change. Tailwnd is designed to uplift both students and educators,
              ensuring that every learner has the opportunity to reach their
              full potential. With a focus on reducing exam anxiety, improving
              retention, and fostering critical thinking, we are preparing
              students to succeed in a rapidly changing world.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Why Tailwnd?</h1>
            <ul className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">
              <li>
                <strong>Data-Driven Approach: </strong>Our platform uses
                advanced algorithms to deliver insights that matter, helping
                educators make informed decisions.
              </li>
              <li>
                <strong>Holistic Learning:</strong>We focus on the "how" and
                "why" of learning, ensuring students not only acquire knowledge
                but also understand and apply it.
              </li>
              <li>
                <strong>Community-Focused:</strong>Tailwnd is committed to
                making a positive social impact by expanding access to
                high-quality education, especially in underserved regions.
              </li>
            </ul>
          </section>
          <section className="pt-3 ps-2">
            <h1  className="tailwnd-h1">Join Us on Our Journey</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              Tailwnd is more than a product – it’s a movement towards a
              brighter, more inclusive future in education. Whether you are an
              educator, student, parent, or stakeholder, we invite you to join
              us on this transformative journey.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1  className="tailwnd-h1">Contact Us</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              Want to learn more about how Tailwnd can make a difference in your
              educational environment? Get in touch with us today, and let’s
              explore how we can partner to create a better future for
              education.
            </p>
          </section>
          </div>
        </div>
         <Footer/>
      </div>
    </>
  );
}

export default AboutUs;
