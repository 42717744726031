import axios from 'axios';

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

const useAxios = () => {
  const getInstance = () => {
    if (typeof window !== 'undefined') {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user && user.accessToken) {
        headers.Authorization = 'Bearer ' + user.accessToken;
        headers.SessionId = user.accessToken;
      }
    }
    const instance = axios.create({
      timeout: 3000000,
      maxContentLength: 500 * 1024 * 1024,
      maxBodyLength: 500 * 1024 * 1024,
      headers: headers,
    });

    instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => console.error(error)
    );

    instance.interceptors.response.use(
      (response) => {
        if (!response.error) return response.data;
        return response.data;
      },
      (error) => {
        return error.response;
      }
    );

    return instance;
  };

  const withoutTokenAxios = () => {
    const instance = axios.create({
      timeout: 3000000,
      maxContentLength: 500 * 1024 * 1024,
      maxBodyLength: 500 * 1024 * 1024,
      headers: headers,
    });

    instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => console.error(error)
    );

    instance.interceptors.response.use(
      (response) => {
        if (!response.error) return response.data;
        return response.data;
      },
      (error) => {
        return error.response;
      }
    );

    return instance;
  };

  const withTokenAxios =  (accessToken) => {

    headers.Authorization = 'Bearer ' + accessToken;
    headers.SessionId = accessToken;

    const instance = axios.create({
      timeout: 3000000,
      maxContentLength: 500 * 1024 * 1024,
      maxBodyLength: 500 * 1024 * 1024,
      headers: headers,
    });

    instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => console.error(error)
    );

    instance.interceptors.response.use(
      (response) => {
        if (!response.error) return response.data;
        return response.data;
      },
      (error) => {
        return error.response;
      }
    );
    return instance;
  };

  return { withoutTokenAxios, withTokenAxios, getInstance };
};

export default useAxios;
