import "../../website/sass/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../website/sass/base/fonts/fonts.css";
import Header from "../Header";
import Footer from "../footer";
import { lastUpdated } from "../../helper/redirection";

function PrivacyPolicy() {
   return (
    <>
      <div className="LandingBody">
        <Header/>
        <div className="container  tailwnd-container  pb-4"
         style={{ backgroundColor: "#D3FEEA" }}>
          <div style={{ backgroundColor: "white" }} className="pt-2 pb-2 px-2">
          <section>
            <h1 className="tailwnd-h2">Our Privacy Policy</h1>
            <h5 className="tailwnd-p tailwnd-f-18 pt-2">Last Updated: {lastUpdated}</h5>
          </section>
          <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">Introduction</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              This Privacy Policy governs the use of data collected by our
              websites www.tailwnd.com and our apps – Tailwnd Family, Tailwnd
              Educator and Tailwnd Student (hereafter, collectively referred to
              as “the Service”, “Tailwnd”, or, “the Tailwnd Service”).
            </p>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              Tailwnd is a brand under Flow Learning Solutions LLP.
            </p>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              By using Tailwnd, you are agreeing to this Privacy Policy. If you
              do not agree, please refrain from using Tailwnd. You can contact
              us anytime with queries about this Privacy Policy at
              info@tailwnd.com.
            </p>
          </section>
          <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">Definitions</h1>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>“Profile” </strong>
                This includes personally identifiable information that we
                collect when you create an account. This may include First Name,
                Last Name, Email, and Phone Number of the user.
              </p>
            </div>
            <div>
              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Class Journal" </strong>
                This includes all the content added to the class journal
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Academic Plans" </strong>
                This includes the Programme of Inquiry, Unit Plans, Learning
                Experiences, Assessments, Schedule, Reflections created by the
                teachers using the planning elements on Tailwnd. The external
                resources added by the teacher are not included in this.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Messages"</strong>
                This includes the messages sent via Tailwnd – both from parents
                to teachers and vice- versa.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Student Portfolio"</strong>
                This includes all the content added to a specific student’s
                portfolio.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Student Data"</strong>
                Any data collected by Tailwnd that can be linked back to an
                individual student. This contains name, age, Email ID, name of
                parents, school name, and the assessment data.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Tailwnd Resource Bank"</strong>
                A collection of all the Academic Plans created by teachers &
                Tailwnd Academic Team. By default, the Academic Plans created by
                school teachers are private to the school.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Insights"</strong>
                Tailwnd analyses the data collected from the teachers and
                students and converts them into actionable points to support
                teachers and students in teaching and learning. This set of
                actionable data-points is collectively referred to as Insights.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Large Language Models (LLMs)"</strong>
                Refers to advanced artificial intelligence models designed to
                process and analyze natural language data on a vast scale. These
                models possess significant computational power and have been
                trained on extensive datasets to understand and generate
                human-like language patterns, responses, and context.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Data Controller/Data Fiduciary"</strong>
                The Data Controller/Data Fiduciary is the entity that decides
                why and how information is used in Tailwnd. In most cases, this
                will be a School.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Data Subject/Data Principal"</strong>
                The Data Subject/Data Principal is the individual (teacher,
                student, parent) to whom the personal data belongs. These
                individuals have rights over their data, such as seeing it,
                correcting it, or deleting it.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>"Data Processor "</strong>
                Tailwnd is a Data Processor. Tailwnd stores and manages
                information according to the instructions given by the Data
                Controller/Data Fiduciary.
              </p>
            </div>
          </section>
          <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">What is Tailwnd?</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              Tailwnd is an AI-powered precision learning platform designed to
              revolutionize education. By providing personalized learning paths,
              real-time data insights, and engaging content, Tailwnd empowers
              both teachers and students to achieve their full potential. Our
              platform is transforming education by bridging the gap between
              traditional teaching methods and modern, data-driven instruction.
            </p>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              The Tailwnd platform has 4 different types of users – Teachers,
              Students, Parents, and School Administrators. Below is a brief
              summary of what each type of user can use the platform for:
            </p>
            <div>
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong >Teachers:</strong>
                Teachers use Tailwnd for planning (Unit Plans, Lesson Plans
                etc), for collecting evidence of learning, for continuous
                reflection, for assessment evaluation and for contributing to
                student portfolios.
              </p>
            </div>
            <div>
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong >Students:</strong>
                Students use Tailwnd to enhance their learning through
                algorithmic tools, document their learning journeys, set
                personalized goals, receive and self-evaluate assessments, and
                build their portfolios
              </p>
            </div>
            <div>
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>Parents:</strong>
                Parents are linked to individual students and can see their
                portfolios. Parents also get access to school calendar, school
                news, and school policies through the Tailwnd. The app can also
                be used for communicating with teachers.
              </p>
            </div>
            <div>
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>Administrators:</strong>
                Administrators can create or upload timetable for school, assign
                syllabus, edit and approve all the academic plans, add, delete
                and edit the rights of other users from their organisation. They
                can also see insights for better program implementation.
              </p>
            </div>
          </section>
          <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">Compliance with DPDPA</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              We are committed to complying with the Digital Personal Data
              Protection Act, 2023 (DPDPA), a comprehensive legislation that
              governs the collection, use, storage, and disclosure of personal
              data in India.
            </p>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              To ensure compliance with DPDPA, we have implemented robust data
              security practices and procedures. We only collect minimal data
              necessary for our services and store it securely using
              industry-standard measures. Our Privacy Policy outlines what data
              we collect and how we use it. Additionally, we have established
              transparent data governance processes to ensure responsible data
              handling. Learn more about Tailwnd and DPDPA <a className="tailwnd-a" href="/dpdpa">here</a>.
            </p>
          </section>
          <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">Data collected by Tailwnd</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              We only collect the data that we need for providing Tailwnd
              services. It is our honest endeavour to minimise the data that we
              collect about our users.
            </p>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              We collect information from all individuals creating an account on
              Tailwnd. This includes teachers, students, parents, other family
              members of students, and schools. De-identified or pseudonymised
              user information is also acceptable.
            </p>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              We also collect log data from all the visitors to our website and
              teachers and school administrators willingly leaving data for our
              marketing campaigns.
            </p>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              Below is a list of data that we collect from our different users
              and how we refer to it:
            </p>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>“Profile” </strong>
                This includes personally identifiable information that we
                collect when you create an account. This may include First Name,
                Last Name, Email and Phone Number of the user.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>“Class Journal” </strong>
                This includes all the content added to the class journal.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>“Academic Plans” </strong>
                This includes the Programme of Inquiry, Unit Plans, Learning
                Experiences, Schedule, Reflections created by the teachers and
                our academic team using all the planning elements as specified
                in the customisable templates. The external resources added by
                the teacher are not included in this.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>“Messages” </strong>
                This includes the messages sent via Tailwnd – both from parents
                to teachers and vice- versa.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>“Student Portfolio” </strong>
                This includes all the content added to a specific student’s
                portfolio – photos, videos, notes, comments, assessments data,
                learning behaviour etc.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>“Student Data” </strong>
                Any data collected by us that can be linked back to an
                individual student. This contains name, age, Email ID, admission
                number, roll number, name of parents and the school name.
              </p>
            </div>
            <div>              
              <p className="tailwnd-p tailwnd-f-18 ms-4">
                <strong>“Log Data” </strong>
                We collect log data such as your IP address, browser type,
                device type, operating system, and your mobile carrier.
                Additionally we also use cookies to keep you logged into your
                system to improve your user experience.
              </p>
            </div>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Where do we store the data?</h1>
            <ul className="tailwnd-p tailwnd-f-18 ms-4 tailwnd-list">
              <li>Our data is hosted on Amazon Web Services (AWS) servers.</li>
            </ul>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">What is the data NOT collected for?</h1>
            <ul className="tailwnd-p tailwnd-f-18 ms-4 tailwnd-list">
              <li>
                We do not allow advertising or sharing data for advertising for
                any data collected through Tailwnd
              </li>
              <li>
                We never display ads, share data for the purpose of displaying
                ads, or allow data collection by advertisers or data brokers
              </li>
              <li>We never sell data to anyone for any purposes</li>
              <li>
                We never allow profiling of our users for targeted online ads
              </li>
            </ul>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Data Retention</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              Tailwnd will keep your data for only as long as it is required or
              as mandated by law or as requested by the 3rd party. Before
              deleting your data, Tailwnd will send out 3 reminders to you.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">When does Tailwnd share data with third parties?</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              We use a few third-party services in order to operate and improve
              Tailwnd. All these services are contractually prohibited from
              using that information for any other purpose other than to provide
              the Tailwnd service.
            </p>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              In case of the sale, merger, bankruptcy, sale of assets or
              reorganisation of our company, we may disclose or transfer your
              data. We will notify you of the same and the terms of this Privacy
              Policy will apply to your data when transferred to the new entity.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Third Party Analytics</h1>
            <ul className="tailwnd-p tailwnd-f-18 ms-4 tailwnd-list">
              <li>
                In order to improve your experience with Tailwnd, we collect and
                use aggregate data about usage patterns of how you use Tailwnd –
                for example, how you interact with various features on a page,
                the buttons that you click, the time that you spend on a page,
                etc. This is done to streamline existing user experience and to
                provide you a better experience of using Tailwnd.
              </li>
              <li>
                We use a small number of third-party services to collect and
                analyse this data (such as Google Analytics, Sentry). These
                services are contractually obligated only to use data about your
                usage of Tailwnd to provide analytics services to us and are
                prohibited from sharing it or using it for other purposes.
              </li>
            </ul>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Tailwnd’s Use of AI</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              This section explains how we collect, use, disclose, and protect
              your information when using Tailwnd AI’s services. Please read
              this section carefully to understand how we handle your data. By
              accessing or using Tailwnd AI’s services, you agree to the
              practices described in this section:
            </p>
            <ol className="tailwnd-p tailwnd-f-18 ms-4 tailwnd-list">
              <li>
                <div>
                  <h1 className="tailwnd-h1">Information Collection and Use</h1>
                  <ul className="tailwnd-p tailwnd-f-18 tailwnd-list">
                    <li>
                      <strong>Pseudonymized Data:</strong> Tailwnd AI uses pseudonymized data
                      collected from schools as context for prompts to LLMs.
                      This data does not contain personally identifiable
                      information (PII) of any individual and is scrubbed of any
                      identifiable details that could be used to trace back to
                      specific individuals. Tailwnd shares data about progress
                      reports, unit plans, learning experiences and interactions
                      of teachers and students with Tailwnd.
                    </li>
                    <li>
                      <strong> LLMs Responses:</strong> The responses from LLMs are generated
                      based on patterns learned from the pseudonymized data and
                      do not contain any specific information about individual
                      users, or any other identifiable parties.
                    </li>
                    <li>
                      The Service Provider uses multiple Large Language Models
                      (such as ChatGPT from Open AI, Gemini from Google, and
                      Llama3.1 from Meta) for providing the services of Tailwnd
                      AI.
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div>
                  <h1 className="tailwnd-h1">Data security</h1>
                  <p>
                    Tailwnd AI takes the security of the data seriously. We
                    implement industry-standard measures to protect the
                    confidentiality and integrity of the data we collect and
                    process. Access to the LLMs and the pseudonymized data is
                    restricted to authorized personnel only and we follow the
                    principle of least privilege.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h1 className="tailwnd-h1">Data Retention</h1>
                  <p>
                    The pseudonymized data collected from schools may be
                    retained for as long as necessary to maintain the
                    effectiveness of the LLMs and the quality of the services.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h1 className="tailwnd-h1">Third-Party Disclosure</h1>
                  <p>
                    Tailwnd AI does not share any personally identifiable
                    information (PII) with the LLMs or any third parties. The
                    LLMs operate solely on pseudonymized and non-identifiable
                    data.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h1 className="tailwnd-h1">Compliance with Laws</h1>
                  <p>
                    Tailwnd AI complies with applicable data protection laws and
                    regulations. We are committed to maintaining the privacy and
                    security of the data we handle.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h1 className="tailwnd-h1">Updates to this Policy</h1>
                  <p>
                    Tailwnd AI may update this policy from time to time to
                    reflect changes in our practices or for other operational,
                    legal, or regulatory reasons. We will provide prominent
                    notice of any material changes to this policy on our website
                    or through other means.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h1 className="tailwnd-h1">Contact Information</h1>
                  <p>
                    If you have any questions, concerns, or requests regarding
                    this policy or Tailwnd AI’s use of LLMs, please contact us
                    at <a className="tailwnd-a" href="mailto:info@tailwnd.com">info@tailwnd.com</a>
                  </p>
                </div>
              </li>
            </ol>
          </section>
          <section  className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Cookie Policy</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              We use Cookies and other similar services (such as Local Storage)
              to keep you logged in to Tailwnd, customize your Tailwnd
              experience, understand how you use Tailwnd, and promote Tailwnd to
              relevant users. You can remove or disable cookies via your browser
              settings, in which case your experience with Tailwnd will not be
              optimal.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Abandoned accounts</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              We consider an account to be abandoned if it has not been accessed
              for over a year. We will delete an account and all content
              associated with such accounts. However, to prevent accidental
              deletion, we will notify the user and any other email IDs
              associated with the account and provide an opportunity to download
              the data of the abandoned account.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Viewing, editing or Porting your information</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              Parents are encouraged to work directly with teachers and school
              to make any changes in your data. If however, you need to get in
              touch with us, you can write to{" "}
              <a className="tailwnd-a" href="mailto:info@tailwnd.com">info@tailwnd.com</a> and we will
              work with the school and do our best to make the required changes.
              Teachers, administrators and parents can directly edit their
              information in their Tailwnd profiles. Schools also have a right
              to use any other similar service and can place a request to get
              all of their data. We will do our best to comply to such requests.
              Once the pending request is processed, the data retention and
              deletion policies will be followed.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Data Protection Practices</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              We follow the latest, industry standards to protect your data.
              Some measures that are in place include use of highly secure,
              access-controlled data centres, data encryption in transit, and
              encryption data at rest etc.
            </p>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              Despite these measures, in the event of a security breach, we will
              notify affected account holders within the amount of time required
              by the local law or by Tailwnd’s internal data breach policy,
              whichever is more stringent, so that you can take steps to keep
              your data safe.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Changes to the Privacy Policy</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              We may from time to time make changes to this Privacy Policy to
              account for changes to our practices or applicable law. If we make
              changes to this Privacy Policy that we believe will materially
              affect your rights, we will notify you by email about these
              changes. If you continue to use our service after you receive
              notice of changes to this Privacy Policy, we will assume that you
              have accepted these changes.
            </p>
            <p className="tailwnd-p tailwnd-f-18 ms-4">
              For previous versions of the Privacy Policy, please reach out to
              us at <a className="tailwnd-a" href="mailto:info@tailwnd.com">info@tailwnd.com</a>
            </p>
          </section>      
          </div>    
        </div>
         <Footer/>
      </div>
    </>
  );
}

export default PrivacyPolicy;
