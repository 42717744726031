import logo from "../../website/images/logo.svg";

function Header(){

    return <div className="header container">
          <div class="container">
            <div class="header-section">
              <div class="logo-outer">
                <a href="/">
                  <img
                    width="auto"
                    height="auto"
                    src={logo}
                    alt="Tailwnd logo"
                  />
                  <span className="fw-bold">Tailwnd</span>
                </a>
              </div>
              <div class="headernav">
                <a
                  href="/login"
                  class="btn login-btn"
                  style={{
                    border: "2px solid",
                    borderRadius: "30px",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "15px 40px",
                  }}
                >
                  Login
                </a>
                <a className="btn btn-primary requestdemo" href="/">
                  Request a Demo
                </a>
              </div>
            </div>
          </div>
        </div>
}

export default Header;