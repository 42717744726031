import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { loginSchema, resetPasswordSchema } from "../../schemas/index";
import "./login.css";
import { useState } from "react";
import { useToast } from "../Toast/toast";
import UserRole from "../../modal/enum/UserRole";
// import AuthNetworkOperation from "../../modal/class/AuthNetworkOperation";
import { enviroment, basePath } from "../../helper/redirection";
import { encodeString } from "../../helper/commonHelper";
import ModalPopup from "../ModalPopup";
import Input from "../Input/Input";
import useAxios from "../../utils/axiosHook";
export default function Login() {
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Incorrect Email, Username or Password"
  );
  const [_userdata, _setUserdata] = useState();
  const [isShow, invokeAction] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const showToast = useToast();
  const { withoutTokenAxios, withTokenAxios } = useAxios();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(loginSchema),
  });
  const userName = register("userName");
  const password = register("password");

  async function login(userName, password) {
    try {
      const { data } = await withoutTokenAxios().post(`${basePath}/login`, {
        userName: String(userName).trim(),
        password: String(password).trim(),
      });
      if (data.error) {
        return data;
      } else {
        const parseUserData = JSON.parse(data?.userData);
        const userUpdatedData = {
          token: data.token,
          userData: parseUserData,
        };
        return userUpdatedData;
      }
    } catch (error) {
      console.error(error);
    }
  }
  const loginHandler = async (data) => {
    try {
      const response = await login(data?.userName, data?.password);
      if (response.error) {
        setErrorMessage(response.message);
        setLoginError(true);
      } else {
        if (response) {
          redirect(response);
        }
      }
    } catch (error) {
      console.log(error);
      setLoginError(true);
      setErrorMessage(error.message);
    }
  };

  const redirect = (response) => {
    let redirectionURI = "";
    const accessTokenStartTime = Date.now();
    const userData = {
      accessToken: response.token,
      accessTokenStartTime,
      ...response.userData,
    };
    switch (response?.userData?.role) {
      case UserRole.SuperAdmin:
      case UserRole.School:
      case UserRole.Coordinator:
      case UserRole.TeachingPlanAdmin:
      case UserRole.Principal:
      case UserRole.VicePrincipal:
      case UserRole.ManagementUser:
      case UserRole.Operation:
        if (enviroment === "stage") {
          redirectionURI = "https://stgadmin.tailwnd.com/";
        } else {
          redirectionURI = "https://admin.tailwnd.com/";
        }
        break;
      case UserRole.Teacher:
        if (enviroment === "stage") {
          redirectionURI = "https://stgteacher.tailwnd.com/";
        } else {
          redirectionURI = "https://teacher.tailwnd.com/";
        }
        break;
      case UserRole.Student:
        if (enviroment === "stage") {
          redirectionURI = "https://stgstudent.tailwnd.com/";
        } else {
          redirectionURI = "https://student.tailwnd.com/";
        }
        break;
      case UserRole.Parent:
        if (enviroment === "stage") {
          redirectionURI = "https://stgparent.tailwnd.com/";
        } else {
          redirectionURI = "https://parent.tailwnd.com/";
        }
        break;
      default:
        redirectionURI = "";
    }
    _setUserdata(userData);
    const authData = encodeString(JSON.stringify(userData));
    redirectionURI = redirectionURI + "?authentication=" + authData;
    if (userData.loginAt) {
      window.location.href = redirectionURI;
    } else {
      setRedirectUrl(redirectionURI);
      initAction();
    }
  };

  const {
    register: resetpasswordRegister,
    handleSubmit: resetPasswordHandelSubmit,
    setValue,
    formState: { error },
  } = useForm({
    resolver: joiResolver(resetPasswordSchema),
  });

  const initAction = () => {
    return invokeAction(!false);
  };
  const initModalClose = () => {
    return invokeAction(false);
  };
  const newPassword = resetpasswordRegister("newPassword");
  const confirmPassword = resetpasswordRegister("confirmPassword");

  const resetPassword = (data) => {
    if (data.newPassword === data.confirmPassword) {
      const _id = encodeString(_userdata._id.toString());
      const schoolId = _userdata.schoolId
        ? encodeString(_userdata.schoolId.toString())
        : null;
      withTokenAxios(_userdata.accessToken)
        .put(
          `${basePath}/resetPassword?_id=${_id}&role=${_userdata.role}${
            schoolId ? `&schoolId=${schoolId}` : ``
          }`,
          {
            password: data.newPassword,
          }
        )
        .then((response) => {
          if (response.error) {
            showToast("error", response.data.message);
          } else {
            initModalClose();
            showToast("success", response.data.message);
            if (redirectUrl) {
              window.location.href = redirectUrl;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      showToast("error", "Password not machted");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(loginHandler)}>
        {/* <form onSubmit={''}> */}
        <div className={"login"}>
          <h2>Log in</h2>
          {loginError && (
            <div className={"input-row"}>
              <label className={"label passworerror"}>{errorMessage}</label>
            </div>
          )}
          <div className={"input-row"}>
            <label className={"label"}>EMAIL OR USERNAME</label>
            <input
              type="text"
              placeholder="Username or Email"
              name="userName"
              {...userName}
            />
            {errors?.userName && (
              <p className={"label passworerror"}>Username is required</p>
            )}
          </div>
          <div className={"input-row"}>
            <label className={"label"}>PASSWORD</label>
            <input
              type="password"
              placeholder="Password"
              name="password"
              {...password}
            />
          </div>
          <div className={"input-row"}>
            <label className={"keepmelogin"}>
              <input type="checkbox" /> Keep me logged in
            </label>
          </div>
          <div className={"input-row"}>
            <button
              type="submit"
              className={"btn loginbtn"}
              style={{ background: "#098efa" }}
            >
              Login now
            </button>
          </div>
          <div className={"input-row"}>
            <label className={"forgotpass"}>
              <div
                onClick={() => {
                  showToast("info", "Please contact with the administrator");
                }}
                style={{ color: "#098efa !important" }}
              >
                Forgot your password?
              </div>
            </label>
          </div>
        </div>
      </form>
      <ModalPopup
        title={"Reset Password"}
        tickIcon={
          <svg
            width="14"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              // eslint-disable-next-line max-len
              d="M0.0654297 5.74707C0.0654297 5.61816 0.0888672 5.49805 0.135742 5.38672C0.188477 5.26953 0.255859 5.16992 0.337891 5.08789C0.425781 5 0.525391 4.93262 0.636719 4.88574C0.753906 4.83301 0.876953 4.80664 1.00586 4.80664C1.25781 4.80664 1.47754 4.90039 1.66504 5.08789L4.75 8.17285L12.335 0.587891C12.5225 0.400391 12.7451 0.306641 13.0029 0.306641C13.1318 0.306641 13.252 0.333008 13.3633 0.385742C13.4805 0.432617 13.5801 0.5 13.6621 0.587891C13.75 0.675781 13.8174 0.77832 13.8643 0.895508C13.917 1.00684 13.9434 1.12402 13.9434 1.24707C13.9434 1.50488 13.8496 1.72754 13.6621 1.91504L5.40918 10.1592C5.32129 10.2471 5.21875 10.3174 5.10156 10.3701C4.99023 10.417 4.87305 10.4404 4.75 10.4404C4.49805 10.4404 4.27832 10.3467 4.09082 10.1592L0.337891 6.41504C0.15625 6.2334 0.0654297 6.01074 0.0654297 5.74707Z"
              fill={"#203161"}
            />
          </svg>
        }
        show={isShow}
        initModalClose={() => invokeAction()}
      >
        <form onSubmit={resetPasswordHandelSubmit(resetPassword)}>
          <div className={"login"}>
            <div className={"formsection"}>
              <div className={"eventrow"}>
                <Input
                  type="password"
                  placeholder="New Password"
                  name="newPassword"
                  {...newPassword}
                  onChange={(e) => setValue("newPassword", e.target.value)}
                  error={error}
                />
              </div>
              <div className={"eventrow"}>
                <Input
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  {...confirmPassword}
                  onChange={(e) => setValue("confirmPassword", e.target.value)}
                  error={error}
                />
              </div>
            </div>
            <div className={"input-row pt-2"}>
              <button type="submit" className={"btn loginbtn pt-2"}>
                Reset Password
              </button>
            </div>
          </div>
        </form>
      </ModalPopup>
    </>
  );
}
