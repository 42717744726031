import { toast } from 'react-hot-toast';

 const useToast = () => {
  const showToast = (type, message, duration = 3000) => {
    switch (type) {
      case 'success':
        toast.success(message, { duration });
        break;
      case 'error':
        toast.error(message, { duration });
        break;
      case 'loading':
        toast.loading(message, { duration });
        break;
      case 'warning':
        toast(message, {icon: '⚠️', duration});
        break;
      case 'custom':
        toast.custom(message, {duration});
        break;  
      default:
        toast(message, { duration });
        break;
    }
  };

  return showToast;
};
export { toast, useToast}
// more options can be created in future https://react-hot-toast.com/docs/toast
