import Footer from "../footer";
import Header from "../Header";

function Pricing() {
  return (
    <>
      <div className="LandingBody">
        <Header />
        <div
          className="container tailwnd-container pb-4"
          style={{ backgroundColor: "#F6EDFF" }}
        >
          <div style={{ backgroundColor: "white" }}>
            <h1 className="tailwnd-h2 pt-3 ps-3 pb-0">Pricing</h1>
            <section className="pt-3 ps-3 pb-2">
              <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
                At Tailwnd, we believe that every student deserves access to
                exceptional education, which is why we offer flexible pricing
                that aligns with your school’s unique requirements and budget.
              </p>
              <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
                Recognizing that no two schools are the same, we go beyond the
                one-size-fits-all approach. Our team carefully evaluates your
                specific needs to craft a pricing plan that ensures maximum
                value from our AI-powered platform, making it both accessible
                and impactful.
              </p>
              <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
                We’re committed to partnering with you to bring quality
                education within reach. Let’s connect to understand your
                school's goals and create a plan tailored just for you. To
                simplify your decision-making, we offer clear pricing options,
                as outlined in the table below:
              </p>
            </section>
            <section className="align-items-center d-flex flex-column flex-md-row  justify-content-around p-md-5 p-0">
              <div className="card p-4 shadow w-100 mx-3" style={{height:'300px'}}>
                <div><h1 className="tailwnd-h1" style={{fontSize:"16px",backgroundColor:'#D3FEEA', width:'max-content', borderRadius:'18px', padding:'8px' }}>Standard</h1></div>
                <div className="pt-2"><h1 className="tailwnd-h2">&#8377; 199/students/mo*</h1></div>
                <div>
                  <ul>
                    <li>AI-driven learning tool</li>
                    <li>Super Learning</li>
                    <li>Vachak Mitra</li>
                    <li>Super Teaching</li>
                  </ul>
                  <small className="text-muted">*inclusive of taxes</small>
                </div>
              </div>
              <div className="card p-4 shadow w-100 mx-3" style={{height:'300px'}}>
                <div ><h1 className="tailwnd-h1" style={{fontSize:"16px",backgroundColor:'#D3FEEA', width:'max-content', borderRadius:'18px', padding:'8px' }}>Custom</h1></div>
                <div  className="pt-2"><h1 className="tailwnd-h2">Standard price + Bespoke add-on charges</h1></div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Pricing;
