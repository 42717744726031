// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tailwnd-container {
  padding-bottom: 3rem !important;
  background: white;
  padding: 16px;
  margin-bottom: 8px;
  box-shadow: 2px 2px 2px 2px #2c2c2c29;
  border-radius: 8px;
}
.tailwnd-h2 {
  font-family: "Prensa-Black";
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0;

  @media (max-width: 991px) {
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 10px;
  }
}
.tailwnd-h1 {
  font-family: "Prensa-Black";
  font-size: 30px;
}
.tailwnd-p {
  margin-top: 8px;
  font-size: 16px;
}

.tailwnd-f-18 {
  font-size: 18px !important;
}
.tailwnd-a {
  text-decoration: underline !important;
  color: blue;
}
ul.tailwnd-list li {
  margin-top: 8px;
  margin-bottom: 8px;
}
li > strong,
strong {
  font-weight: 800 !important;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,qCAAqC;EACrC,kBAAkB;AACpB;AACA;EACE,2BAA2B;EAC3B,eAAe;EACf,iBAAiB;EACjB,gBAAgB;;EAEhB;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;AACF;AACA;EACE,2BAA2B;EAC3B,eAAe;AACjB;AACA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,qCAAqC;EACrC,WAAW;AACb;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;;EAEE,2BAA2B;AAC7B","sourcesContent":[".tailwnd-container {\n  padding-bottom: 3rem !important;\n  background: white;\n  padding: 16px;\n  margin-bottom: 8px;\n  box-shadow: 2px 2px 2px 2px #2c2c2c29;\n  border-radius: 8px;\n}\n.tailwnd-h2 {\n  font-family: \"Prensa-Black\";\n  font-size: 40px;\n  line-height: 50px;\n  margin-bottom: 0;\n\n  @media (max-width: 991px) {\n    font-size: 25px;\n    line-height: 40px;\n    margin-bottom: 10px;\n  }\n}\n.tailwnd-h1 {\n  font-family: \"Prensa-Black\";\n  font-size: 30px;\n}\n.tailwnd-p {\n  margin-top: 8px;\n  font-size: 16px;\n}\n\n.tailwnd-f-18 {\n  font-size: 18px !important;\n}\n.tailwnd-a {\n  text-decoration: underline !important;\n  color: blue;\n}\nul.tailwnd-list li {\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\nli > strong,\nstrong {\n  font-weight: 800 !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
