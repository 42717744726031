import Footer from "../footer";
import Header from "../Header";
import contactUsImage from "../../website/images/caller.svg";
function ContactUs() {
  return (
    <>
      <div className="LandingBody">
        <Header />
        <div
          className="container tailwnd-container  pb-4"
          style={{ backgroundColor: "#F6EDFF" }}
        >
          <div style={{ backgroundColor: "white" }} className="pt-2 pb-2 px-2">
            <h1 className="tailwnd-h2 ms-4 ">Contact Us</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              Want to learn more about how Tailwnd can make a difference in your
              educational environment? Get in touch with us today, and let’s
              explore how we can partner to create a better future for
              education.
            </p>
            <section className=" d-flex flex-column flex-md-row flex-nowrap justify-content-between">
              <div className="w-100">
                <img width="auto" height="auto" src={contactUsImage} alt="" />
              </div>
              <div className="w-100">
                <h1>
                  Have a question?
                  <br /> Get in touch with us.
                </h1>
                <p className="tailwnd-h1 ms-4 pt-4">Tailwnd | Flow Learning Solutions LLP</p>
                <address className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
                  <strong>Registered Address:</strong> 575/I, Gaur Cascades, Raj
                  Nagar Extension, Ghaziabad, Uttar Pradesh - 201017
                </address>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
	  	  <strong >Phone Number: </strong>
                  <a className="tailwnd-a"  href="tel:+918287785441" class="Blondie">
                    +91 8287785441
                  </a>
                </p>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
                  <strong>Email: </strong>
                  <a className="tailwnd-a"  href="mailto:info@tailwnd.com" class="Blondie">
                   info@tailwnd.com
                  </a>
                </p>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ContactUs;
