import { CRYPTOKEY } from './redirection';

const crypto = require('crypto-js');

export function scrollToTarget(targetId) {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
    }
}
export function copyToClipboard(contentToCopy) {

    navigator.clipboard.writeText(contentToCopy)
    return contentToCopy;
}

export  function encodeString(str) {
    try {
      let encrypted = crypto.AES.encrypt(str, CRYPTOKEY).toString();
      if (!encrypted || encrypted === 'undefined') {
        throw new Error('Please send valid string');
      }   
      return encodeURIComponent(encrypted);
    } catch (error) {
      console.error('Error occurred in encodeString of file commonHelper :: ', error);
      throw error;
    }
}