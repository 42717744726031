
const joi = require('joi');

export const loginSchema = joi.object({
  userName: joi.string().required(),
  password: joi.string().required(),
});

export const resetPasswordSchema = joi.object({
  newPassword: joi.string().required().messages({ 'string.empty': "Enter Password" }),
  confirmPassword: joi.string().required().messages({ 'string.empty': "Enter confirmation Password" }),
});

export const requestDemo = joi.object({
  fullName: joi.string().required().messages({ 'string.empty': "Enter Full name" }),
  email: joi.string().email({ tlds: false }).allow('')
  .messages({'string.email':'Enter valid email address'}),
  mobile: joi.string().pattern(/^[789]\d{9}$/).allow('')
  .messages({'string.pattern.base':'Mobile number must start with 9, 8, or 7 and be 10 digits long'}),
  message: joi.string().optional().allow(''),
}).custom((value, helpers) => {
  const email = value.email || '';
  const mobile = value.mobile || '';
  
  if (!email && !mobile) {
    return helpers.error('any.required');
  }
  
  return value;
}, 'email').required();;
