import { lastUpdated } from "../../helper/redirection";
import Footer from "../footer";
import Header from "../Header";

function CookiesPolicy() {
  return (
    <>
      <div className="LandingBody">
        <Header />
        <div className="container tailwnd-container  pb-4"
        style={{ backgroundColor: "#FEE2D6" }}
        >
              <div style={{ backgroundColor: "white" }} className="pt-2 pb-2 px-2">
            <section>
                <h1 className="tailwnd-h2">Cookie Policy</h1>
                <p className="tailwnd-p">Last Updated : {lastUpdated}</p>
                <p className="tailwnd-p tailwnd-f-18">At Tailwnd, we are committed to protecting your privacy. This Cookie Policy explains how we use cookies and similar technologies on our website, <a className="tailwnd-a" href="https://tailwnd.com">www.tailwnd.com</a>. By continuing to use our site, you agree to the use of cookies as described in this policy.</p>
            </section>
            <section className="pt-5">
                <h1 className="tailwnd-h1">1. What are Cookies?</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4">Cookies are small text files that are stored on your device (computer, tablet, smartphone, etc.) when you visit websites. They help websites recognize your device and store information about your preferences or past actions.</p>
            </section>
            <section className="pt-3">
                <h1 className="tailwnd-h1">2. Types of Cookies We Use</h1>
                <ul className="tailwnd-p tailwnd-f-18 ms-4 tailwnd-list">
                    <li><strong>Essential Cookies:</strong> These cookies are necessary for the website to function properly. Without these cookies, certain parts of the website would not work as intended.</li>
                    <li><strong>Performance Cookies: </strong>These cookies collect information about how visitors use our website, such as which pages are most frequently visited. This data is used to improve the website’s performance and user experience.</li>
                    <li><strong>Functionality Cookies: </strong>These cookies allow the website to remember your preferences and choices (such as your username or language) to provide a more personalized experience.</li>
                    <li><strong>Targeting/Advertising Cookies: </strong>These cookies are used to deliver relevant ads to you based on your interests. They also help measure the effectiveness of advertising campaigns.</li>
                </ul>
            </section>
            <section className="pt-3">
                <h1 className="tailwnd-h1">3. How We Use Cookies</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4">We use cookies to:</p>
                <ul className="tailwnd-p tailwnd-f-18 ms-4 tailwnd-list">
                    <li>Improve the functionality and performance of our website.</li>
                    <li>Remember your preferences and settings.</li>
                    <li>Analyze site traffic and usage to enhance user experience.</li>
                    <li>Deliver personalized content and advertisements.</li>
                </ul>
            </section>
            <section className="pt-3">
                <h1 className="tailwnd-h1">4. Managing Cookies</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4">You can control and manage cookies through your browser settings. Most browsers allow you to refuse or delete cookies. However, please note that disabling cookies may affect the functionality of our website and your user experience.</p>
            </section>
            <section className="pt-3">
                <h1 className="tailwnd-h1">5. Third-Party Cookies</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4">We may allow third-party service providers to use cookies on our website to help deliver content and advertisements relevant to your interests. These third-party cookies are governed by the respective third-party’s privacy policies.</p>
            </section>
            <section className="pt-3">
                <h1 className="tailwnd-h1">6. Changes to This Cookie Policy</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4">We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated effective date.</p>
            </section>
            <section className="pt-3">
                <h1 className="tailwnd-h1">7. Contact Us</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4">If you have any questions or concerns about our use of cookies, please contact us at info@tailwnd.com.</p>
                <p className="tailwnd-p tailwnd-f-18 ms-4">By using <a className="tailwnd-a" href="https://tailwnd.com">www.tailwnd.com</a>, you consent to our use of cookies in accordance with this policy.</p>
            </section>
            </div>
        </div>
        <Footer/>
      </div>
    </>
  );
}

export default CookiesPolicy;
