import { lastUpdated } from "../../helper/redirection";
import Footer from "../footer";
import Header from "../Header";

function Dpdpa() {
  return (
    <>
      <div className="LandingBody">
        <Header />
        <div className="container tailwnd-container pb-4" style={{ backgroundColor: "#FEE2D6" }}>
            <div style={{ backgroundColor: "white" }} className="pt-2 pb-2 px-2">
        <h1 className="tailwnd-h2">How Tailwnd Protects Your Data Under DPDPA </h1>
	  <h5 className="tailwnd-p tailwnd-f-18 pt-2">Last Updated: {lastUpdated}</h5>
          <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">Lawful Processing</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              Tailwnd collects and processes your personal data only for
              specified, legitimate purposes outlined in our comprehensive
              Privacy Policy.
            </p>
          </section>
          <section className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Security Measures</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              Your data security is our priority. We use robust security
              measures to protect your personal information from unauthorized
              access, changes, or misuse. We continuously adapt our practices to
              stay ahead of new threats.
            </p>
          </section>
          <section  className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Data Breach Notification</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              In the unlikely event of a data breach that compromises your
              personal information, Tailwnd will promptly notify the Data
              Principal(s) and the Data Protection Board (yet to be established
              by India’s central government), taking immediate steps to contain
              the situation and prevent further harm.
            </p>
          </section>
          <section  className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Parental Consent</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              Tailwnd understands the importance of protecting the privacy of
              children (defined as individuals below the age of 18). As a Data
              Processor under the Digital Personal Data Protection Act, 2023
              (DPDPA), Tailwnd processes data only on the instructions of the
              Data Fiduciary (typically the school/teacher). The Data Fiduciary
              is responsible for obtaining verifiable parental consent before
              sharing any child’s personal data with us. To support this
              process, Tailwnd has provided a consent form which can be
              downloaded from here.
            </p>
          </section>
          <section  className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Right of Access</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              You can reach Tailwnd at any time to see a summary of all your
              personal data being processed along with the related processing
              activities. You can also request the identity of any third-party
              Data Processors and Data Fiduciaries with whom your personal data
              has been shared.
            </p>
          </section>
          <section  className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Right to Correction, Update & Completion</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              Tailwnd respects your right to have accurate and complete
              information. If you find that any personal data we hold is
              inaccurate, incomplete, or needs updating, you can request its
              correction, update, or completion by contacting{" "}
              <a href="mailto:info@tailwnd.com">info@tailwnd.com</a>.
            </p>
          </section>
          <section  className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Right to Erasure</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              If you no longer wish to use Tailwnd’s services and want your data
              deleted from our records, please contact our team, and we will
              delete all of your information from our records unless we are
              legally required to retain certain data.
            </p>
          </section>
          <section  className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Right of Grievance Redressal</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              If you have any questions or concerns about your data privacy
              rights under the DPDPA, you can reach out to Tailwnd’s Data
              Protection Officer, at{" "}
              <a href="mailto:info@tailwnd.com">info@tailwnd.com</a>.
            </p>
          </section>
          <section  className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Right to Nominate an agent</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              The DPDPA recognizes your right to appoint a representative to
              manage your data privacy rights in case of death or
              incapacitation. Tailwnd will uphold your designated
              representative’s authority.
            </p>
          </section>
          <section  className="pt-3 ps-2">
            <h1 className="tailwnd-h1">Our employees</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
              All our employees get periodically trained in relevant data
              security practices. They complete background checks, and sign
              non-disclosure agreements and immediately lose access to all
              systems and data when terminated.
            </p>
          </section>
          </div>
          </div>
           <Footer/>
      </div>
    </>
  );
}

export default Dpdpa;
