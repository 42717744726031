import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const ModalPopup = ({ title, children, initModalClose, modelName, ...reset }) => {
  return (
    <Modal
      {...reset}
      centered
      className={`creategroup  ${modelName === 'AURA' ? 'largeHeight' : ''} `}
      size={(modelName === 'AURA'|| reset?.size ==='large') ? 'lg' : ''}
    >
      <Modal.Header closeButton onClick={initModalClose}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={title === 'Add Members' ? '' : ''}>
        {children}
      </Modal.Body>
    </Modal>
  );
};

ModalPopup.propTypes = {
  title: PropTypes.string.isRequired,
  tickIcon: PropTypes.any,
  buttonList: PropTypes.arrayOf(PropTypes.element),
};

ModalPopup.defaultProps = {
  title: null,
  tickIcon: null,
  buttonList: [],
};
export default ModalPopup;
