import '../../assets/fonts/fonts.css';
import React from 'react'
import '../login/login.css'
import logo from '../../website/images/loginLogo.png'
import Login from '../login/login'
import { enviroment } from '../../helper/redirection';



function LoginLayout() {
  return (
    <div className={`${'loginOuter'} loginOuter`}>
      <div className={`${'loginLeft'} loginLeft`}>
        <h2>Welcome! </h2>
        <div className={`${'loginLogo'} loginLogo`}>
        <a href={enviroment ==="stage" ? "https://stage.tailwnd.com" : "https://tailwnd.com"} style={{ color: '#098efa' }}><img src={logo} alt="Login" /></a>
        </div>
        <div className={'notMember'}>
          Not a member yet? &nbsp; <a href={enviroment ==="stage" ? "https://stage.tailwnd.com/#request_demo" : "https://tailwnd.com/#request_demo"} style={{ color: '#098efa' }} className='text-decoration-underline'>Contact Sales</a>
        </div>
      </div>
      <div className={`${'loginRight'} loginRight`}>
        <Login />
      </div>
    </div>
  )
}

export default LoginLayout
