import { lastUpdated } from "../../helper/redirection";
import Footer from "../footer";
import Header from "../Header";

function CancellationPolicy() {
  return (
    <>
      <div className="LandingBody">
        <Header />
        <div
          className="container tailwnd-container  pb-4"
          style={{ backgroundColor: "#FDF0C8" }}
        >
          <div style={{ backgroundColor: "white" }} className="pt-2 pb-3 px-2">
            <section  className="pt-3 ps-3">
              <h1 className="tailwnd-h2">Refund and Cancellation Policy</h1>
              <p className="tailwnd-p">Last Updated : {lastUpdated}</p>
              <p className="tailwnd-p tailwnd-f-18">
                At Tailwnd, we strive to provide our customers with the best possible experience. We understand that circumstances may change, and you may need to modify or cancel your subscription. Below is our general refund and cancellation policy:
              </p>
            </section>
            <section className="pt-3 ps-3">
                <h1 className="tailwnd-h1">1. Subscription Period and Renewal</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">1.1. All subscriptions are valid for a period of twelve (12) months from the date of activation, unless stated otherwise.</p>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">1.2. Subscriptions will automatically renew at the end of each subscription period unless cancelled at least 30 days before the renewal date.</p>
            </section>
            <section className="pt-3 ps-3">
                <h1 className="tailwnd-h1">2. Cancellation Policy</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">2.1. Subscribers may cancel their subscription at any time by providing written notice to Tailwnd.</p>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">2.2. Cancellation requests must be submitted at least 60 days before the renewal date to avoid being charged for the next subscription period.</p>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">2.3. Upon cancellation, access to the platform and all associated services will be terminated at the end of the current subscription period.</p>
            </section>
            <section className="pt-3 ps-3">
                <h1 className="tailwnd-h1">3. Refund Policy</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">3.1. Tailwnd does not offer refunds for any unused portion of the subscription period once a subscription is active.</p>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">3.2. In the event of cancellation, no refunds will be issued for the remainder of the subscription period.</p>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">3.3. Refunds may be considered on a case-by-case basis in exceptional circumstances, such as billing errors or system malfunctions.</p>
            </section>
            <section className="pt-3 ps-3">
                <h1 className="tailwnd-h1">4. Service Modifications</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">4.1. If you wish to modify your subscription, such as changing the service plan or features, please contact our support team.</p>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">4.2. Modification requests should be made in writing and may be subject to additional fees or prorated charges.</p>
            </section>
            <section className="pt-3 ps-3">
                <h1 className="tailwnd-h1">5. Termination by Tailwnd</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">5.1. Tailwnd reserves the right to terminate any subscription at its discretion, with or without cause. In such cases, a prorated refund for the unused portion of the subscription shall be issued.</p>                
            </section>
            <section className="pt-3 ps-3">
                <h1 className="tailwnd-h1">6. Contact Information</h1>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">For any questions, cancellations, or refund requests, please contact our customer support team at <a className="tailwnd-a" href="mailto:info@tailwnd.com">info@tailwnd.com</a>.</p>
                <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2 tailwnd-list">This policy is designed to be fair and transparent while maintaining the quality of service that Tailwnd is known for.</p>
            </section>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
}

export default CancellationPolicy;
