import styles from "./Input.module.css";
import { forwardRef } from "react";

const Input = (
  {
    icon,
    type,
    placeholder,
    name,
    mandatory,
    options,
    error,
    register,
    componentName,
    getTranslatorHandler,
    classNamePrefix = "selectoption",
    targetName,
    helpText,
    ...reset
  },
  ref
) => {
  const _icon = icon;
  return (
    <div className={`${styles.formRow} `}>
      <div className={`${styles.group} ${mandatory ? styles.mandatory : ""}`}>
        {icon && <div className={styles.icon}>{_icon}</div>}
        <div
          className={`${styles.inputRow} ${error?.[name] ? "error" : ""}`}
          style={{
            border: `${type === "select" ? `1px solid black` : ``}`,
            borderRadius: `${type === "select" ? "12px" : ""}`,
          }}
        >
          {type !== "select" &&
            type !== "CreatableSelect" &&
            type !== "textarea" &&
            type !== "date" &&
            type !== "translator" && (
              <input
                ref={ref}
                name={name}
                className={`${styles.input} ${
                  error?.[name] ? styles.error : ""
                }`}
                type={type}
                placeholder={placeholder}
                {...reset}
              />
            )}
          {type === "textarea" && register ? (
            <textarea
              ref={ref}
              name={name}
              rows={1}
              className={`${styles.input} ${
                error?.[name] ? styles.error : ""
              } tailwnd-textarea`}
              type={type}
              value={reset.itemValue ? reset.itemValue : reset.value}
              placeholder={placeholder}
              {...register(componentName)}
              {...reset}
            ></textarea>
          ) : (
            type === "textarea" && (
              <textarea
                ref={ref}
                name={name}
                rows={1}
                className={`${styles.input} ${
                  error?.[name] ? styles.error : ""
                } tailwnd-textarea`}
                type={type}
                value={reset.itemValue ? reset.itemValue : reset.value}
                placeholder={placeholder}
                {...reset}
              ></textarea>
            )
          )}
          {!targetName && error && error[name] && (
            <p className={[styles.label, styles.error].join(" ")}>
              {error[name]?.message}
            </p>
          )}
          {targetName && error && error[targetName] && (
            <p className={[styles.label, styles.error].join(" ")}>
              {error[targetName].message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
Input.displayName = "Input";
export default forwardRef(Input);
