/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import "../../website/sass/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../website/sass/base/fonts/fonts.css";
import "./style.css";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useToast } from "../Toast/toast";
import useAuthRepository from "../../repository/authRepository";
import AuthNetworkOperation from "../../modal/class/AuthNetworkOperation";


// images

import logo from "../../website/images/logo.svg";
// import logo from '../../website/images/logo.svg';
import Effectiveness from "../../website/images/Effectiveness.png";
import heighter from "../../website/images/heighter.png";
// import action from '../../website/images/action.png';
import action from "../../website/images/action.png";
import timetable from "../../website/images/timetable.png";
import Study_Plans from "../../website/images/Study-Plans.png";
import learning from "../../website/images/learning.png";
import Intelligence from "../../website/images/Intelligence.png";
import familyimg from "../../website/images/familyimg.png";
import obj1 from "../../website/images/obj1.png";
import obj2 from "../../website/images/obj2.png";
import obj3 from "../../website/images/obj3.png";
import obj4 from "../../website/images/obj4.png";
import obj5 from "../../website/images/obj5.png";
import obj6 from "../../website/images/obj6.png";
import obj7 from "../../website/images/obj7.png";
import obj8 from "../../website/images/obj8.png";
import obj9 from "../../website/images/obj9.png";
import obj10 from "../../website/images/obj10.png";
import obj11 from "../../website/images/obj11.png";
import obj12 from "../../website/images/obj12.png";
import obj13 from "../../website/images/obj13.png";
import obj14 from "../../website/images/obj14.png";
import obj15 from "../../website/images/obj15.png";
import footerlogo from "../../website/images/footerlogo.png";
import { copyToClipboard } from "../../helper/commonHelper.js";
import { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { requestDemo } from "../../schemas/index.js";
import Input from "../Input/Input.jsx";
import { useLocation } from "react-router-dom";
import Footer from "../footer/index.js";

function Landing() {
  const salesEmail = "sales@tailwnd.com";
  const [effectiveness, setEffectiveness] = useState(1);
  const [clipboardStatus, setClipboardStatus] = useState(null);  
  const [isVisible, setIsVisible] = useState(false);
  const formRef = useRef(null);
  const showToast = useToast();
  const AuthRepository = useAuthRepository();
  const location = useLocation();
  const authNetworkOperation = useMemo(
    () => new AuthNetworkOperation(AuthRepository),
    [AuthRepository]
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset 
  } = useForm({
    resolver: joiResolver(requestDemo),
  });
  const fullName = register("fullName");
  const email = register("email");
  const mobile = register("mobile");
  const message = register("message");
  useEffect(() => {
    if (clipboardStatus) {
      showToast("success", `${clipboardStatus} copied to clipboard`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipboardStatus]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling behavior
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true); // Show button when user scrolls down
    } else {
      setIsVisible(false); // Hide button when user scrolls to top
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const hashSegment =  location.hash.substring(1);
    if(hashSegment === "request_demo"){
      scrollToTarget();
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const saveProductEnquiry = async (formData) => {
    try {
      const data = await authNetworkOperation.saveProductEnquiry(formData);   
      console.log(data);
      if(data?.data?.error){
        showToast("error", data.data.message);
        return ;
      }
      if (data.error) {
        if(data.message === "Email/Mobile/userName already registered"){
            showToast("error", "You've already submitted your request. We will be in touch with you shortly.");    
            return;
        }
        showToast("error", data.message);
      } else {
        showToast("success", "Thank you for submitting the request. we'll be in touch with you shortly");
        reset();
      }
    } catch (error) {
      showToast("error", "something went wrong");
      console.error(error);
    }
  };
  function scrollToTarget() {
   if(formRef.current){
      formRef.current.scrollIntoView({ behavior: 'smooth' });
   }
    
}
  return (
    <body className="LandingBody">
      <div class="header container">
        <div class="container">
          <div class="header-section">
            <div class="logo-outer">
              <a href="/">
                <img width="auto" height="auto" src={logo} alt="Tailwnd logo" />
                <span className="fw-bold">Tailwnd</span>
              </a>
            </div>
            <div class="headernav">
              <a
                href="/login"
                class="btn login-btn"
                style={{
                  border: "2px solid",
                  borderRadius:"30px",
                  fontSize: "16px",
                  fontWeight:600,
                  padding: "15px 40px",
                }}
              >
                Login
              </a>
              <button
                className="btn btn-primary requestdemo"
                onClick={() => scrollToTarget("HowToUse")}
              >
                Request a Demo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-outer">
        <div class="container">
          <div class="bannertext">
            <div class="text">
              <h3>{`Unlock student's full potential as`}</h3>
              <h1>Super Learners</h1>
              <button
                className="btn  requestdemo-big slDemoBtn"
                onClick={() => scrollToTarget("HowToUse")}
              >
                Request a Demo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="effective-section-outer">
        <div class="container">
          <div class="toptext">
            <h2>
              An AI-Driven{" "}
            </h2>
            <h2> Neuroscience Based Learning</h2>
            <p>
              Tailwnd elevates your learning potential to a 10X level by using 
              smart strategies that match how your brain naturally learns.
            </p>
          </div>
          <div class="tabbing-outer">
            <div class="tabbing">
              <div class="effctivness">
                <a
                  href="javascript:void(0)"
                  className={effectiveness === 1 && "active"}
                  id="effctivnesslink"
                  onClick={() => {
                    setEffectiveness(1);
                  }}
                >
                  Effectiveness
                </a>
                <a
                  href="javascript:void(0)"
                  className={effectiveness === 2 && "active"}
                  id="demolink"
                  onClick={() => {
                    setEffectiveness(2);
                  }}
                >
                  HOTS
                </a>
              </div>
              <button
                className="btn btn-primary requestdemo"
                onClick={() => scrollToTarget("HowToUse")}
              >
                Request a Demo
              </button>
            </div>
            <div class="tabimg">
              {effectiveness === 1 ? (
                <div class="tabimgsection" id="effctivness">
                  <img width="auto" height="auto" src={Effectiveness} alt="" />
                </div>
              ) : (
                <div class="tabimgsection" id="demo">
                  <img width="auto" height="auto" src={heighter} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="tailwindaction-outer">
        <div class="container">
          <div class="toptext">
            <h2>Tailwnd In Action</h2>
            <p>
              Allow your school to make data-driven decisions, and your students
              to learn in a more efficient way!
            </p>
            <button
              className="btn btn-primary requestdemo"
              onClick={() => scrollToTarget("HowToUse")}
            >
              Request a Demo
            </button>
          </div>
          <div class="imgsection">
            <img width="auto" height="auto" src={action} alt="" />
          </div>
        </div>
      </div>
      <div class="tow-column-common">
        <div class="container">
          <h2>Kickstart With Timetabling AI</h2>
          <div class="tow-column-outer">
            <div class="column text-start">
              <div class="toptext">
                <p>
                  Relieving schools from the hassle of creating timetable and
                  the daily stress of handling teacher's absence. Our efficient
                  algorithmic tool gets the job done swiftly.
                </p>
                <button
                  className="btn btn-primary requestdemo"
                  onClick={() => scrollToTarget("HowToUse")}
                >
                  Request a Demo
                </button>
              </div>
            </div>
            <div class="column">
              <img width="auto" height="auto" src={timetable} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="tow-column-common">
        <div class="container">
          <h2>Headway With Curated Study Plans</h2>
          <div class="tow-column-outer">
            <div class="column text-start">
              <div class="toptext">
                <p>
                  Teaching is an art. We help teachers create the masterpiece
                  with our descriptive yet precise lesson plans.
                </p>
                <button
                  className="btn btn-primary requestdemo"
                  onClick={() => scrollToTarget("HowToUse")}
                >
                  Request a Demo
                </button>
              </div>
            </div>
            <div class="column">
              <img width="auto" height="auto" src={Study_Plans} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="tow-column-common">
        <div class="container">
          <h2>Achieve 10X Learning</h2>
          <div class="tow-column-outer">
            <div class="column text-start">
              <div class="toptext">
                <p>
                  AI-driven neuroscientific learning strategies facilitate
                  accelerated learning, deeper understanding, and long-lasting
                  memory retention for students.
                </p>
                <button
                  className="btn btn-primary requestdemo"
                  onClick={() => scrollToTarget("HowToUse")}
                >
                  Request a Demo
                </button>
              </div>
            </div>
            <div class="column">
              <img width="auto" height="auto" src={learning} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="tow-column-common">
        <div class="container">
          <h2>Intelligence</h2>
          <div class="tow-column-outer">
            <div class="column text-start">
              <div class="toptext">
                <p>
                  {`From the first moment you start you can know the general
                    performance of your school according to each intelligence. Our
                    algorithms do the tedious work so you can focus on what's
                    important.`}
                </p>
                <button
                  className="btn btn-primary requestdemo"
                  onClick={() => scrollToTarget("HowToUse")}
                >
                  Request a Demo
                </button>
              </div>
            </div>
            <div class="column">
              <img width="auto" height="auto" src={Intelligence} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="family-accrout-outer">
        <div class="container">
          <div class="imgTitle">
            <img width="auto" height="auto" src={familyimg} alt="" />
            <h3>Family Account</h3>
          </div>
          <div class="toptext">
            <p>
              {` Engage families as essential partners in children's education by
                keeping parents informed about their children's day, progress, and
                school activities in real-time. This ensures effective and focused
                parent-teacher conversations.`}
            </p>
            <button
              className="btn btn-primary requestdemo"
              onClick={() => scrollToTarget("HowToUse")}
            >
              Request a Demo
            </button>
          </div>
        </div>
      </div>
      <div class="learning-outer">
        <div class="container">
          <h2 className="firstRow" >
            Let’s Put Tailwnd To{" "}
          </h2>
          <h2>Teaching and Learning!</h2>
          <div class="learing-section">
            <div class="learing-box clr1">
              <div class="img">
                <img width="auto" height="auto" src={obj1} alt="" />
              </div>

              <h4>Interleaving</h4>
              <p>Sharpen your brain</p>
            </div>
            <div class="learing-box clr2">
              <div class="img">
                <img width="auto" height="auto" src={obj2} alt="" />
              </div>
              <h4>Spaced Repetition</h4>
              <p>Flatten forgetting curve</p>
            </div>
            <div class="learing-box clr3">
              <div class="img">
                <img width="auto" height="auto" src={obj3} alt="" />
              </div>
              <h4>Elaborative Interrogation</h4>
              <p>Deep dive to the core</p>
            </div>
            <div class="learing-box clr4">
              <div class="img">
                <img width="auto" height="auto" src={obj4} alt="" />
              </div>
              <h4>Feynman Technique</h4>
              <p>Deepen your understanding</p>
            </div>
            <div class="learing-box clr5">
              <div class="img">
                <img width="auto" height="auto" src={obj5} alt="" />
              </div>
              <h4>Retrieval</h4>
              <p>Strengthen neural links</p>
            </div>
            <div class="learing-box clr6">
              <div class="img">
                <img width="auto" height="auto" src={obj6} alt="" />
              </div>
              <h4>Quests</h4>
              <p>Challenge yourself</p>
            </div>
          </div>
        </div>
      </div>
      <div class="learning-outer more">
        <div class="container">
          <h2>And There Is More!</h2>
          <div class="toptext">
            <p>
              {` Tailwnd Transforms Tablets And Smartphones Into Allies Of Your
                Student's Education, Ensuring Their Screen Time Contributes
                Positively To Their Future.`}
            </p>
          </div>
          <div class="learing-section">
            <div class="learing-box clr1">
              <div class="img">
                <img width="auto" height="auto" src={obj7} alt="" />
              </div>
              <h4>Knowledge Repository</h4>
              <p>Access wealth of smart knowledge resources</p>
            </div>
            <div class="learing-box clr2">
              <div class="img">
                <img width="auto" height="auto" src={obj8} alt="" />
              </div>
              <h4>Super Learning</h4>
              <p>Practice with spaced repetition to boosts memory</p>
            </div>
            <div class="learing-box clr3">
              <div class="img">
                <img width="auto" height="auto" src={obj9} alt="" />
              </div>
              <h4>Vachak Mitra</h4>
              <p>Helps students develop their reading comprehension skills</p>
            </div>
            <div class="learing-box clr4">
              <div class="img">
                <img width="auto" height="auto" src={obj10} alt="" />
              </div>
              <h4>Concept Map</h4>
              <p>Visualize ideas for better understanding and memory</p>
            </div>
            <div class="learing-box clr5">
              <div class="img">
                <img width="auto" height="auto" src={obj11} alt="" />
              </div>
              <h4>Mock Test</h4>
              <p>Replicate exam conditions to evaluate course comprehension</p>
            </div>
            <div class="learing-box clr6">
              <div class="img">
                <img width="auto" height="auto" src={obj12} alt="" />
              </div>
              <h4>Achievements</h4>
              <p>{`Recognize and celebrate student's accomplishments`}</p>
            </div>
            <div class="learing-box clr7">
              <div class="img">
                <img width="auto" height="auto" src={obj13} alt="" />
              </div>
              <h4>Discussion Forum</h4>
              <p>Collaborative learning among students and educators</p>
            </div>
            <div class="learing-box clr8">
              <div class="img">
                <img width="auto" height="auto" src={obj14} alt="" />
              </div>
              <h4>Absence management</h4>
              <p>Manage day-to-day cover assignment when teachers are absent</p>
            </div>
            <div class="learing-box clr9">
              <div class="img">
                <img width="auto" height="auto" src={obj15} alt="" />
              </div>
              <h4>Mobile/Web Compatible</h4>
              <p>Seamless access and usability across various devices</p>
            </div>
          </div>
        </div>
      </div>
      <div class="learning-outer more">
      <div class="container" ref={formRef} id="howtoUse">
        <h2>How to Use?</h2>
        <div class="howtouse">
          <div class="leftsection">
            <ul>
              <li>
                <div class="column">
                  <div class="number"><span>1</span></div>
                </div>
                <div class="column">
                  <h3>Discover</h3>
                  <p>
                    See how Tailwnd helps your school in a quick, 40-minute
                    demo.
                  </p>
                </div>
              </li>
              <li>
                <div class="column">
                  <div class="number"><span>2</span></div>
                </div>
                <div class="column">
                  <h3>Customize</h3>
                  <p>
                    Focus on what matters. We configure the platform for your unique needs.
                  </p>
                </div>
              </li>
              <li>
                <div class="column">
                  <div class="number"><span>3</span></div>
                </div>
                <div class="column">
                  <h3>Start</h3>
                  <p>
                    Seamless Onboarding Equipped with Training and Resources.
                  </p>
                </div>
              </li>
              <li>
                <div class="column">
                  <div class="number"><span>4</span></div>
                </div>
                <div class="column">
                  <h3>Grow</h3>
                  <p>
                    Effortless Knowledge Management. Support at Your Fingertips.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div class="rightsection">
            <div class="rowsection">
              <h3>Request a Demo</h3>
              <p>
                  {" "}
                  {`Feel free to send us a message using this convenient form. We look `}
                  <br/>
                  {`forward to hearing from you!`}
                </p>
            </div>
            <form onSubmit={handleSubmit(saveProductEnquiry)}>
              <div class="rowsection flex youremail">
                <div class="col">
                  <h3>To:</h3>
                </div>
                <div class="col">
                  <button class="email">sales@tailwnd.com</button>
                </div>
                <div class="col"><button class="copy" onClick={async () => {
                      const clipboardStatus = await copyToClipboard(salesEmail);
                      setClipboardStatus(clipboardStatus);
                    }}>Copy Email</button></div>
              </div>
              <div class="rowsection flex">
                <div class="col">
                  <h3>Full name :{" "}</h3>
                </div>
                <div class="col">
                  <Input
                    type="text"
                    placeholder="Your Name"
                    mandatory={true}
                    class="inputemail"
                    name="fullName"
                    {...fullName}                    
                    error={errors}
                  />
                </div>
              </div>
              <div class="rowsection flex">
                <div class="col">
                  <h3>Email:</h3>
                </div>
                <div class="col">
                  <Input
                    name="email"
                    type="text"
                    placeholder="Your Email"
                    class="inputemail"
                    {...email}
                    error={errors}
                  />
                </div>
              </div>
              <div class="rowsection flex">
                <div class="col">
                  <h3>Phone Number:</h3>
                </div>
                <div class="col">
                  <Input
                    name="mobile"
                    type="text"
                    placeholder=" Phone Number"
                    class="inputemail" 
                    maxLength="10"
                    {...mobile}
                    error={errors}
                  />
                </div>
              </div>
              <div class="rowsection flex">
                <div class="col">
                  <h3>Message:</h3>
                </div>
                <div class="col">
                  <Input
                    type="textarea"
                    placeholder="Your message"
                    name="message"
                    cols="30"
                    rows="10"
                    class="message"
                    {...message}
                    error={errors}
                  />
                </div>
              </div>
              <div class="rowsection flex">
                <div class="center-block col d-flex justify-content-center">
                  <button class="submit"   type="submit"              
                  >Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

      <div className="ScrollToTop" id="ScrollToTop">
        <button
          className={`scroll-to-top-button ${isVisible ? "show" : ""}`}
          onClick={scrollToTop}
        >
          ↑
        </button>
      </div>
      <Footer/>  
	  { /* <div class="footer">
        <div class="container">
          <div class="footer-content">
            <div class="column">
              <img width="auto" height="auto" src={footerlogo} alt="" />
            </div>
            <div class="column">
              <p>Copyright © 2024. All rights reserved.</p>
            </div>
            <div class="column d-flex justify-content-around">
              <p>Privacy policy</p>
              <p>Terms of service </p>
            </div>
          </div>
        </div> 
	</div> */}
      <Toaster position="bottom-center" />
    </body>
  );
}

export default Landing;
